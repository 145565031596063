<template>
  <div class="login">
    <div class="login-form">
      <el-form :model="LoginData" :rules="rules">
        <h1>登录</h1>
        <el-row type="flex">
          <el-col>
            <el-form-item label="账号" prop="loginID">
              <el-input v-model="LoginData.loginID" class="login-form-input"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex">
          <el-col>
            <el-form-item label="密码" prop="password">
              <el-input v-model="LoginData.password" class="login-form-input" show-password></el-input>
            </el-form-item>
          </el-col>
        </el-row>
<!--        <el-form-item label="密码" prop="password">-->
<!--          <el-input v-model="LoginData.password" class="login-form-input" show-password></el-input>-->
<!--        </el-form-item>-->
        <el-row type="flex" justify="space-between">
          <el-col :span="4">
            <el-button type="text" @click="Login()">忘记密码</el-button>
          </el-col>
          <el-col :span="6">
            <el-button type="primary" @click="Login()" style="margin-left: auto">登录</el-button>
          </el-col>
        </el-row>
<!--        <el-form-item>-->
<!--          <div class="login-form-button">-->
<!--            <el-button type="text" @click="Login()">忘记密码</el-button>-->
<!--            <el-button type="primary" @click="Login()" style="margin-left: auto">登录</el-button>-->
<!--          </div>-->

<!--        </el-form-item>-->
      </el-form>
    </div>
  </div>

</template>

<script>
import {userLogin} from "@/api/login"

export default {
  name: "index",
  data() {
    return {
      LoginData: {
        loginID: '',
        password: '',
      },
      rules: {
        loginID: [
          { required: true, message: '请输入账号', trigger: 'blur' },
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
        ],
      }
    };
  },
  methods: {
    Login(){
      userLogin().then(res=>{
        this.UserSet()
        this.$router.push("home")
        location.reload();
      })
    },
    UserSet(){
      let user=123456
      this.$Cookie.set('user',user)
    }
  }
}
</script>

<style lang="scss" scoped>
.login{

  display: block;
  &-form{
    width: 400px;
    margin: auto;
    &-input{
      width: 80%;
    }
    &-button{
      width: 90%;
      display: flex;
    }
  }

}

</style>
