<template>
  <div>
    <div class="title">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>用户管理</el-breadcrumb-item>
      </el-breadcrumb>
      <div>
        <h1>欢迎使用用户管理系统</h1>
        <h3 style="margin-left: auto;margin-top:auto">当前管理员：XXXX 管理员等级：1</h3>
      </div>
      <div>
        <h2>用户列表</h2>
        <div class="title-search">
          <download-excel
              :fields="exportDataStandard"
              :data="mainData"
              :name="excelName">
            <el-button type="primary" style="height: 40px;margin-right: 25px">数据导出</el-button>
          </download-excel>
          <el-input v-model="Keyword" placeholder="请输入关键字" clearable></el-input>
          <el-button type="primary" style="height: 40px" @click="">搜索</el-button>
        </div>
      </div>
    </div>
    <div class="list">
      <el-table
          :border="true"
          :data="nowData"
          max-height="850px">
        <el-table-column
            prop="number"
            label="序号"
            width="80">
            <template slot-scope="scope">
            {{ scope.$index + 1 + (currentPage - 1) * pageSize }}
          </template>
        </el-table-column>
        <el-table-column
            prop="nickname"
            label="登录名称"
            width="180">
        </el-table-column>
        <el-table-column
            prop="mobile"
            label="手机"
            width="180">
        </el-table-column>
        <el-table-column
            prop="company"
            label="商家名称"
            width="180">
        </el-table-column>
        <el-table-column
            align="center"
            prop="userType"
            label="当前角色"
            width="180"
            >
        <el-tag color="bule" >主账号</el-tag>
        </el-table-column>
        <el-table-column
            prop="createTime"
            label="注册时间"
            width="180">
        </el-table-column>

        <el-table-column
            prop="updateTime"
            label="最近登录时间"
            width="180">
        </el-table-column>
        <el-table-column
            align="center"
            prop="user_state"
            label="当前状态"
            width="180">
          <template slot-scope="scope">
            <el-tag type="success">已启用</el-tag>
            <!-- <el-tag type="info" >已冻结</el-tag> -->
          </template>
        </el-table-column>
        <el-table-column
            align="center"
            label="操作"
            width="180">
          <template slot-scope="scope">
            <el-button type="primary" icon="el-icon-edit" circle @click="open(scope.row)"></el-button>
            <el-button type="danger" icon="el-icon-delete" circle @click="deleteUser(scope.row)"></el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="page">
      <el-pagination
          @current-change="setNowData"
          :page-size="10"
          layout="total, prev, pager, next, jumper"
          :total=tableLength>
      </el-pagination>

    </div>
    <el-dialog
        title="信息"
        :visible.sync="dialogVisible"
        width="30%">
      <el-form style="width: 90%;">
        <div style="text-align: center">
          <div class="box">
            <span>用户名：</span>
            <el-input class="box-data" v-model="udata.username"></el-input>
          </div>
          <div class="box">
            <span>手机：</span>
            <el-input class="box-data" v-model="udata.mobile"></el-input>
          </div>
          <div class="box">
            <span>商家名称：</span>
            <el-input class="box-data" v-model="udata.company"></el-input>
          </div>
          <div class="box">
            <span>当前状态：</span>
            <el-radio-group v-model="udata.user_state" class="box-data">
              <el-radio-button label="正常"></el-radio-button>
              <!-- <el-radio-button label="冻结"></el-radio-button> -->
            </el-radio-group>
          </div>
          <div class="box">
            <span>当前账号：</span>
            <el-radio-group v-model="udata.isSub" class="box-data">
              <el-radio-button  label="主账号"></el-radio-button>
              <!-- <el-radio-button  label="子账号"></el-radio-button> -->
            </el-radio-group>
          </div>
        </div>
        <div class="box" style="text-align: center;padding-top: 50px">
          <el-button type="primary" @click="updata()">提交</el-button>
          <el-button @click="dialogVisible = false">取 消</el-button>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>

import {getAllUserData} from '@/api/usermanager'

export default {
  name: "index",
  
  data() {
    return {
      nowData: [],
      mainData: [],
      currentPage: 1,
      pageSize:10,
      tableLength: 0,
      Keyword: '',
      excelName: '',
      dialogVisible: false,
      exportDataStandard: {
        "序号": "number",
        "名字": "username",
        "电话": "mobile",
        "商家名称": "company",
        "注册时间": "createTime",
        "最近登录时间": "updateTime",
        "vip等级": "package",
        "账号状态": "user_state",
      },
      udata: ''
    }
  },
  methods: {
    getAllUser() {
      getAllUserData({Keyword:this.Keyword,pageSize:this.pageSize,page:this.tableLength}).then(res => {
        console.log(res,'res')
        this.mainData = res.data.data.records
        this.setNowData(1)
        this.tableLength = this.mainData.length
      })
    },
    setNowData(page) {
      this.currentPage = page
      this.nowData = this.mainData.slice((page - 1) * 10, page * 10)
    },
    setName() {
      const date = new Date();
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      this.excelName = "用户数据" + year + month + day + ".xls"
    },
    open(data) {
      this.udata = data
      this.dialogVisible = true
    },
    updata() {
      console.log(this.udata)
      this.dialogVisible = false
    }
  
  },
  created() {
    this.getAllUser()
    this.setName()
  },
}
</script>

<style lang="scss" scoped>


.title div {
  display: flex;
}

.title {

  &-search {
    margin-left: auto;
  }

  &-search * {
    margin: auto;
  }
}

.page {
  margin-top: 25px;
}

.box {
  text-align: right;
  margin: 5px;

  &-data {
    width: 60%;
  }

  span {
    padding-right: 10px;
  }
}

</style>
