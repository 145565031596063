<template>
  <div class="box">
    <div class="box-title">
      <h1>欢迎使用后台管理员系统</h1>
      <div class="box-right">
        <div class="box-right-btn">
          <el-button round icon="el-icon-s-finance" class="btn" @click="dialogVisible=true" style="font-size: 25px;">更多优惠</el-button>
        </div>

        <h1 style="margin: auto;">当前管理员{{user}}</h1>
      </div>

    </div>
    <div>
      <el-row type="flex" :gutter="20" align="middle">
        <el-col :span="6">
          <div class="tab" @click="jumpUserManager">
            <h1 style="">用户管理</h1>
          </div>
        </el-col>

        <el-col :span="6">
          <div class="tab" @click="jumpBillManager">
            <h1 style="">账单管理</h1>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="unTab">
            <h3 style="">开发中</h3>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="unTab">
            <h3 style="">开发中</h3>
          </div></el-col>
      </el-row>
    </div>
    <el-dialog
        title="优惠"
        :visible.sync="dialogVisible"
        width="30%">
      <el-row>
        <el-col span="12">
          <el-button class="btn" @click="getcode()">点击获得优惠券/兑换码</el-button>
          <h3 v-if="code!=null||code==''">您的兑换码是:{{code}}</h3>
        </el-col>

        <el-col span="12">

          <el-input style="width: 50%"placeholder="请输入兑换码" clearable v-model="upcode"></el-input><el-button class="btn">点击兑换</el-button>
        </el-col>
      </el-row>
    </el-dialog>
  </div>

</template>

<script>


export default {

  name: "index",
  data(){
    return {
      user:this.$Cookie.get('user'),
      dialogVisible:false,
      code:null,
      upcode:''
    }
  },
  methods:{
    jumpUserManager(){
      this.$router.push('/userManager')
    },
    jumpBillManager(){
      this.$router.push('/billManager')
    },
    getcode(){
      this.code=123456
    }
  }
}
</script>

<style lang="scss" scoped>
.tab{
  background-color: cornflowerblue;
  height: 10vh;
  border-radius: 15px;
  display: flex;
  h1{
    margin: auto;
  }
}
.unTab{
  display: flex;
  background-color: lightgray;
  height: 10vh;
  border-radius: 15px;
  h3{
    margin: auto;
  }
}
.btn{
  color: white;
  font-weight :bold;
  background-color: gold;
}
.btn:hover{
  background-color: gold;
  color: inherit;
  box-shadow: none;
  border-color: inherit;
}
.btn:focus {
  background-color: gold;
  color: inherit;
  box-shadow: none;
  border-color: inherit;
}
.box{
  &-title{
    text-align: left;
    display: flex;
    font-size: 25px;
  }
  &-right{
    margin-left: auto;
    display: flex;
    &-btn{
      margin: auto 15px auto auto
    }
  }
}
</style>
