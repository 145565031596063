<template>
  <div>
    <el-menu
        :default-active="Path"
        class="el-menu-vertical-demo"
        :router="true">
      <el-menu-item index="/home">
        <i class="el-icon-menu"></i>
        <span slot="title">首页</span>
      </el-menu-item>
      <el-menu-item index="/userManager">
        <i class="el-icon-user-solid"></i>
        <span slot="title">用户管理</span>
      </el-menu-item>
      <el-menu-item index="/billManager">
        <i class="el-icon-document"></i>
        <span slot="title">账单管理</span>
      </el-menu-item>
      <el-menu-item index="" disabled>
        <i class="el-icon-document"></i>
        <span slot="title">开发中</span>
      </el-menu-item>
    </el-menu>
  </div>

</template>

<script>
export default {
  name: "Left",

  data(){
    return{
      Path:''
    }
  },
  methods:{
    clear(){
      localStorage.clear()
    }
  },
  watch: {
    $route: function(to, from) {	//监视$route的变化
      //设置导航菜单高亮的路径为前两段路径
      this.Path = this.$route.matched[1].path;	//看你的情况而定！！！
    }
  },
}
</script>

<style scoped>

</style>
