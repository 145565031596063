<template>
  <el-container >
    <el-header class="Top"><Top></Top></el-header>
    <el-container>
      <el-aside style="background-color: #2c3e50"><Left></Left></el-aside>
      <el-main class="Right">
        <div class="Right-home">
          <div class="Right-home-box">
            <Right></Right>
          </div>

        </div>

      </el-main>
    </el-container>
  </el-container>

</template>

<script>
import Top from "@/views/layout/Top";
import Left from "@/views/layout/Left";
import Right from "@/views/layout/Right";
export default {
  name: "index",
  components: {Right, Left, Top}
}
</script>

<style lang="scss" scoped>
.Top{
  background-color: cornflowerblue;
  width: 100%;
  height: 10vh !important;
}
.Right{
  background-color: #e8ffe8;
  min-height: 90vh;
  width:  100%;
  &-home{
    background-color: white;
    border-radius: 25px;
    &-box{
      padding: 15px;
    }
  }
}

</style>
