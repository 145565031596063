<template>
  <div>
    <div class="title">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>账单管理</el-breadcrumb-item>
      </el-breadcrumb>
      <div>
        <h1>欢迎使用账单管理系统</h1>
        <h3 style="margin-left: auto;margin-top:auto">当前管理员：XXXX 管理员等级：1</h3>
      </div>
      <div>
        <h2>购买记录</h2>
        <div class="title-search">
          <download-excel :fields="exportDataStandard" :data="mainData" :name="excelName">
            <el-button type="primary" style="height: 40px; margin-right: 25px">数据导出</el-button>
          </download-excel>
          <el-input v-model="input" placeholder="请输入关键字" clearable></el-input>
          <el-button type="primary" style="height: 40px" @click="search">搜索</el-button>
        </div>
      </div>
    </div>
    <div class="list">
      <el-table :border="true" :data="nowData" max-height="850px">
        <el-table-column prop="number" label="序号" width="80">
          <template slot-scope="scope">
            {{ scope.$index + 1 + (currentPage - 1) * pageSize }}
          </template>
        </el-table-column>
        <el-table-column prop="userName" label="用户" width="200">
        </el-table-column>
        <el-table-column prop="mobile" label="手机" width="180">
        </el-table-column>
        <el-table-column prop="serviceName" label="购买服务" width="180">
        </el-table-column>
        <el-table-column prop="payMethod" label="支付方式" width="150">
        </el-table-column>
        <el-table-column prop="payAmt" label="支付金额" width="150">
        </el-table-column>
        <el-table-column prop="createTime" label="购买时间" width="180">
        </el-table-column>
        <el-table-column prop="endTime" label="失效时间" width="">
        </el-table-column>
      </el-table>
    </div>
    <div class="page">
      <el-pagination @current-change="setNowData" :page-size="10" layout="total, prev, pager, next, jumper"
        :total="filteredTableLength">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { getAllBillData } from '@/api/billmanager'

export default {
  name: "index",
  created() {
    this.getAllBill()
    this.setName()
  },
  data() {
    return {
      nowData: [],
      mainData: [],
      keyword: '',
      currentPage: 1,
      pageSize: 10,
      tableLength: 0,
      filteredTableLength: 0,
      input: '',
      excelName: '',
      exportDataStandard: {
        "序号": "number",
        "名字": "userName",
        "电话": "mobile",
        "购买服务": "serviceName",
        "支付方式": "payMethod",
        "支付金额": "payAmt",
        "购买时间": "createTime",
        "失效时间": "endTime",
      }
    }
  },
  methods: {
    getAllBill() {
      getAllBillData({ keyword: this.keyword, pageSize: this.pageSize, page: this.tableLength }).then(res => {
        this.mainData = res.data.data.records
        this.setNowData(1)
        this.tableLength = this.mainData.length
      })
    },
    setNowData(page) {
      this.currentPage = page
      let data = this.filteredData || this.mainData
      this.nowData = data.slice((page - 1) * 10, page * 10)
    },
    setName() {
      const date = new Date();
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      this.excelName = "账单数据" + year + month + day + ".xls";
    },
    // search() {
    //   if (this.Keyword && this.mainData && this.mainData.length > 0) {
    //     if (this.mainData.some(item => item && item.nickname && item.nickname.includes(this.Keyword))) {
    //       if (this.input.trim() === '') {
    //         this.filteredData = null
    //         this.filteredTableLength = this.tableLength
    //         this.setNowData(1)
    //         return
    //       }
    //       this.filteredData = this.mainData.filter(item => {
    //         return [
    //           item.userName,
    //           item.mobile,
    //           item.serviceName,
    //           item.payMethod,
    //           item.payAmt.toString(),
    //           item.createTime,
    //           item.endTime
    //         ].some(field => field.includes(this.input))
    //       })

    //       this.filteredTableLength = this.filteredData.length
    //       this.setNowData(1)
    //     }
    //   }
    // }

  },
  // computed: {
  //   filteredData() {
  //     return this.$data.filteredData
  //   }
  // }
}
</script>

<style lang="scss" scoped>
.title div {
  display: flex;
}

.title {
  &-search {
    margin-left: auto;
  }

  &-search * {
    margin: auto;
  }
}

.page {
  margin-top: 25px;
}
</style>
