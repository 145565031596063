/**
 * article模块接口列表
 */
import axios from "axios";
// export function getAllUserData() {
//     return axios({
//         url: 'mock/userManager/getAll',
//         method: 'get'
//     })
// }

// const api = {
//     listUserInfo:'/api/usercenter/listUserInfo'
// };
// export function getAllUserData(parameter){
//     return axios({
//         url: api.listUserInfo,
//         method: 'post',
//         data: parameter,
//     })
// }

export function getAllUserData(data) {
    return axios({
        url: '/api/usercenter/listUserInfo',
        method: 'post',
        data
    })
}
