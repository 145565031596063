<template>
  <div>
    <el-row type="flex" align="middle" style="height: 10vh">
      <el-col :span="3"><h1 style="font-size: 30px">用户管理系统</h1></el-col>
      <el-col :span="1" :offset="20">
        <el-button v-if="!user">登录</el-button>
        <el-button v-else @click="clear">注销</el-button>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "Top",
  created() {
  },
  data(){
    return{
      user:this.$Cookie.get('user')
    }
  },
  methods:{
    clear(){
      this.$Cookie.remove('user')
      location.reload();
    }
  }
}
</script>

<style lang="scss" scoped>
.top-title{
  height: 10vh;
  &-login{
    margin: auto 25px auto auto;
  }
}
.top-title h1{
  margin: auto;
}
</style>
