/**
 * article模块接口列表
 */
import axios from "axios";

// export function getAllBillData() {
//     return axios({
//         url: 'mock/billManager/getAll',
//         method: 'get'
//     })
// } 

// const api = {
//     listBillInfo:'/api/usercenter/listBillInfo'
// };
// export function getAllBillData(parameter) {
//     return axios({
//         url: api.listBillInfo,
//         method: 'post',
//         data: parameter,
//     })
// }

export function getAllBillData(data){
    return axios({
        url: '/api/usercenter/listBillInfo',
        method: 'post',
        data
    })
}
