/**
 * article模块接口列表
 */
import axios from "axios";

export function userLogin() {
    return axios({
        url: 'mock/login',
        method: 'get'
    })
}
