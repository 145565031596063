<template>
  <el-container class="container">
    <el-header class="container-Header" ><top></top></el-header>
    <el-container>
      <el-aside class="Left"><Left></Left></el-aside>
      <el-main><div class="ma">helllo</div></el-main>
    </el-container>
  </el-container>
</template>

<script>
import Top from "@/views/layout/Top";
import Left from "@/views/layout/Left";
export default {
  name: "index",
  components: {Left, Top}
}
</script>

<style scoped lang="scss">

.Header{
  height:200px;
  background-color: royalblue;
}
.container{

}
.Left{
  background-color: blue;
  height: 100vh;
}
.ma{

  background-color: red;
}
</style>
