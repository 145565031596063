import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import scroll from 'vue-seamless-scroll'
import axios from 'axios'
import JsonExcel from 'vue-json-excel'
import Cookie from 'js-cookie'

Vue.component('downloadExcel', JsonExcel)
Vue.prototype.$axios = axios
Vue.prototype.$Cookie = Cookie
Vue.use(scroll)
Vue.use(ElementUI);
Vue.config.productionTip = false
require("./mock/index")

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
