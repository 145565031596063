const Mock = require("mockjs");
var Random = Mock.Random
let userData = Mock.mock({
    "data|20": [ //生成10条数据 数组
        {
            "number|+1": 1,//生成id，自增1
            "username": "@cname",//名字， 都是中国人的名字
            "phone": /^1(5|3|7|8)[0-9]{9}$/,//生成随机电话号
            "business": Random.cword(5, 7)+'有限公司', //随机商家
            "user_type|1": ["主账号","子账号"],
            "register_time": Random.date(), //注册时间
            "login_time": Random.date(), //最近登录时间
            "package|1":["vip1","vip2","vip3"],//vip等级
            "user_state|1": ["正常","冻结"]
        }
    ]
})
let billData = Mock.mock({
    "data|30": [ //生成10条数据 数组
        {
            "number|+1": 1,//生成id，自增1
            "username": "@cname",//名字， 都是中国人的名字
            "phone": /^1(5|3|7|8)[0-9]{9}$/,//生成随机电话号
            "buy_serve|1": ["vip1","vip2","vip3"], //购买服务
            "pay_type|1": ["微信","支付宝","现金"], //支付方式
            "pay": Random.integer(168,648),//支付金额
            "buy_time": Random.date(),//购买时间
            "end_time": Random.date()//失效时间
        }
    ]
})
let LoginData=Mock.mock({
    "user":'123456'
})
Mock.mock('mock/userManager/getAll','get',userData)
Mock.mock('mock/billManager/getAll','get',billData)
Mock.mock('mock/login','get',LoginData)
