import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '../views/layout/Index'
import Login from '../views/login/Index'
import UserManager from '../views/usermanager/Index'
import billManager from '../views/billmanager/Index'
import test from '../views/index'
import Home from '../views/home/Index'
import Cookie from 'js-cookie'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'layout',
    component: Layout,
    children:[
      {
        path: '/home',
        name: 'home',
        component: Home,
      },
      {
        path: '/userManager',
        name: 'userManager',
        component: UserManager,
      },
      {
        path: '/billManager',
        name: 'billManager',
        component: billManager,
      },
      {path: '/login',
        name: 'login',
        component: Login,

      },
      {
        path: '/',
        redirect: '/home'
      }
    ]
  },{
    path: '/test',
    name: test,
    component: test
  }

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
router.beforeEach((to, from, next) => {
  if (to.path === '/login') {
    next()
  } else {
    const User=Cookie.get('user')
    console.log(User)
    // let token = localStorage.getItem('user');
    if (User == null || User == '') {
      next('/login');
    } else {
      next();
    }
  }
})
